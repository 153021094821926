






































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class AdminEnvironmentHome extends Vue {
  quickAccess = [{ to: './settings/users', icon: 'person', text: 'Usuarios' }]

  get currentEnvironment() {
    const firstEntry = this.$store.state.app.recents[0]
    if (firstEntry && firstEntry._id === this.envId) {
      return this.$store.state.app.recents[0]
    }
  }

  get logoEnviroment() {
    return (
      this.currentEnvironment?.loginLogo?.url ||
      this.currentEnvironment?.logo?.url
    )
  }

  get envId() {
    return this.$route.params.environmentId
  }

  mounted() {
    if (this.currentEnvironment) {
      this.$store.commit('dashboard/setMiniDrawer', false)
    }
  }
}
